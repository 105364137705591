/* eslint-disable no-console */
/**
 * Import third-party libraries.
 */
import axios, { AxiosError, AxiosResponse } from 'axios';

/**
 * Import custom types.
 */
import {
  CountryDto,
  OrganizationRecordWrapper,
  Organization,
  OrganizationMember,
  OrganizationRole,
  Service,
  User,
  UserRecordWrapper,
  AddOrganizationMemberInvitation,
  OrganizationService,
  OrganizationServiceMember,
  ServiceRole,
  UpdateUser,
  ServiceUserRecordWrapper,
  OrganizationInvitationResponse,
  UpdateUserLocalizationDTO,
  LanguageDto,
  ServiceOrganizationRecordsWrapper,
  ServicePlan,
  Subscription,
  SubscriptionCreation,
  ImpersonateUserRequestDTO,
  Recipient,
  SubscriptionUpdate,
  DomainValidationDTO,
  ScenarioDTO,
  AddOrganizationServiceMemberInvitation,
  ScenarioWhitelistItemDTO,
  ScenarioItemPatchDTO,
  ScenarioItemDTO,
  UpdateOrganizationServiceDTO,
  MarketDTO,
  SessionDTO,
  OrganizationMergeRequest,
  OrganizationMergeValidationResponse,
  TermsOfServiceDocument,
  SalesOrgDto,
  CreateTermsOfServiceDto,
  UpdateToSDto,
  CreateOrganization,
  Feature,
  Permission,
  InvitationResponse,
  AuditLogEventType,
  AuditLogEventsRecordsWrapper,
  UserStates,
  AddOrgMemberResponse,
  CountryConfiguration,
  UserProfile, LocalSegment, SubscriptionCreateScheduledAmendmentDto, SubscriptionAmendment,
} from '../types';
import { BaseApi } from './base.api';

class Api extends BaseApi {
  // Globally used variables within the API class.
  public token = '';
  private laerdalApiInstance = axios.create();
  static instance = new Api();

  /**
   * Creates an instance of the API singleton class.
   * Adds interceptors and other configurations.
   */
  constructor() {
    super();
    // Add request interceptor
    this.addRequestInterceptor(
      (config) => {
        // Let's add default request headers
        config.headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getToken()}`,
          ...config.headers,
        };

        return config;
      },
      (error) => Promise.reject(error),
    );

    // Add response interceptor
    this.addResponseInterceptor(
      (successResponse: AxiosResponse<any>) => successResponse,
      (errorResponse: AxiosError<any>) => {
        // Return API message
        if (errorResponse.response?.data?.title) {
          return Promise.reject(errorResponse.response.data.title);
        } else if (errorResponse.response) {
          // Generate error message
          return Promise.reject(this.errorHandler(errorResponse.response.status) ?? errorResponse);
        } else {
          return Promise.reject(`Something went wrong. Please try again in a moment`);
        }
      },
    );
  }

  /**
   * Returns an error message for a specific error code.
   * @param statusCode - Status code based on which error message will be generated.
   * @returns A string containing an error message.
   */
  errorHandler = (statusCode: number): string | void => {
    if (statusCode === 401) {
      return 'You are not authorized to do this action';
    } else if (statusCode === 500) {
      return 'There was a system error, please try again in a moment';
    }
  };

  /**
   * Adds axios response interceptor.
   */
  addResponseInterceptor = (onFulfilled?: (value: any) => any | Promise<any>, onRejected?: (error: any) => any) => {
    this.laerdalApiInstance.interceptors.response.use(onFulfilled, onRejected);
  };

  /**
   * Adds axios request interceptor.
   */
  addRequestInterceptor = (onFulfilled?: (value: any) => any | Promise<any>, onRejected?: (error: any) => any) => {
    this.laerdalApiInstance.interceptors.request.use(onFulfilled, onRejected);
  };

  /**
   * Stores the Office365 access token.
   */
  storeJWT = (token: string) => {
    this.token = token;
  };

  /**
   * Retrieves the token from the context.
   * @returns A JWT token for the currently authenticated user.
   */
  getToken = (): string => {
    // Let's return the token
    return this.token;
  };

  /**
   * Retrieves users last login information.
   * @param id - id of the  user for which to retrieve last login date.
   * @returns User last login information.
   */
  getUserLastLogin = async (id: string): Promise<any[]> => {
    return this.laerdalApiInstance.get(`/api/user/last-login?id=${id}`).then((response) => response?.data);
  };

  /**
   * Retrieves information if the user has a Gigya account.
   * @param email - E-mail address against which to validate if user has Gigya account.
   * @returns Boolean value indicating if the user has Gigya account.
   */
  HasGigyaAccount = async (email: string | null): Promise<boolean> => {
    return this.laerdalApiInstance
      .get(`/api/user/has-gigya-account?email=${email ? encodeURIComponent(email) : ''}`, {
        headers: {
          EntityId: email ?? '',
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Retrieves a specific service data.
   * @param id - ID of the service for which data needs to be retrieved.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns Data for a specific service.
   */
  GetService = async (id: string, abortSignal?: AbortSignal): Promise<Service> => {
    return this.laerdalApiInstance.get(`/api/service/${id}`, { signal: abortSignal }).then((response) => response?.data);
  };

  /**
   * Retrieves a specific service data.
   * @param id - ID of the service for which data needs to be retrieved.
   * @returns Data for a specific service.
   */
  GetServiceRoles = async (id: string): Promise<ServiceRole[]> => {
    return this.laerdalApiInstance.get(`/api/service/${id}/roles`).then((response) => response?.data);
  };

  /**
   * Retrieves a specific service plans.
   * @param id - ID of the service for which plans needs to be retrieved.
   * @returns Plans for a specific service.
   */
  GetServicePlans = async (id: string): Promise<ServicePlan[]> => {
    return this.laerdalApiInstance.get(`/api/service/${id}/plans`).then((response) => response?.data);
  };

  /**
   * Retrieves all services from the API.
   * @returns List of services.
   */
  GetServices = async (includeStatistics: boolean = false, abortSignal?: AbortSignal): Promise<Service[]> => {
    return this.laerdalApiInstance.get('/api/service?includeStatistics=' + includeStatistics, { signal: abortSignal }).then((response) => response?.data);
  };

  /**
   * Find and retrieves a list of service users from the API.
   * @param query - Query based on which to filter the user results.
   * @param start - From which row to retrieve data (offset).
   * @param count - How many rows to retrieve (limit).
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   * @returns A record wrapper containing found organization list.
   */
  FindServiceUsers = async (id: string, query: string, start?: number, count?: number, sortBy?: number, sortDirection?: number): Promise<ServiceUserRecordWrapper> => {
    let uri = `/api/service/${id}/users?query=${query}`;

    if (start != null) {
      uri += `&start=${start}`;
    }
    if (count != null) {
      uri += `&count=${count}`;
    }

    // Let's assign sorting
    if (sortBy !== undefined && sortDirection !== undefined) {
      uri += `&sortBy=${sortBy}&sortOrder=${sortDirection}`;
    }

    return this.laerdalApiInstance.get(uri).then((response) => response?.data);
  };

  /**
   * Find and retrieves a list of organizations tied to a service from the API.
   * @param query - Query based on which to filter the organization results.
   * @param start - From which row to retrieve data (offset).
   * @param count - How many rows to retrieve (limit).
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   * @param internalOnly - Filter only internal organizations.
   * @param expirationDateFrom - Filter expiration date from
   * @param expirationDateTo - Filter expiration date to
   * @returns A record wrapper containing found organization list.
   */
  FindServiceOrgs = async (
    id: string,
    query: string,
    internalOnly?: boolean,
    expirationDateFrom?: Date,
    expirationDateTo?: Date,
    start?: number,
    count?: number,
    sortBy?: number,
    sortDirection?: number,
  ): Promise<ServiceOrganizationRecordsWrapper> => {
    let uri = `/api/service/${id}/organizations?query=${query}`;

    if (start != null) {
      uri += `&start=${start}`;
    }
    if (count != null) {
      uri += `&count=${count}`;
    }

    // Let's assign sorting
    if (sortBy !== undefined && sortDirection !== undefined) {
      uri += `&sortBy=${sortBy}&sortOrder=${sortDirection}`;
    }

    if (internalOnly !== undefined) {
      uri += `&internalOnly=${internalOnly}`;
    }

    if (expirationDateFrom !== undefined) {
      uri += `&expirationDateFrom=${expirationDateFrom.toISOString()}`;
    }

    if (expirationDateTo !== undefined) {
      uri += `&expirationDateTo=${expirationDateTo.toISOString()}`;
    }

    return this.laerdalApiInstance.get(uri).then((response) => response?.data);
  };

  /**
   * Finds and retrieves a list of users from the API.
   * @param query - Query string based on which to filter user list.
   * @param start - From which row to retrieve data (offset).
   * @param count - How many rows to retrieve (limit).
   * @param organizationId - Organization ID based on which to filter the list.
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns A record wrapper containing list of users.
   */
  FindUsers = async (
    query: string,
    start?: number,
    count?: number,
    organizationId?: string,
    sortBy?: number,
    sortDirection?: number,
    state?: UserStates | null,
    abortSignal?: AbortSignal,
  ): Promise<UserRecordWrapper> => {
    let uri = `/api/user/all?query=${encodeURIComponent(query)}`;

    if (start != null) {
      uri += `&start=${start}`;
    }
    if (count != null) {
      uri += `&count=${count}`;
    }
    if (organizationId) {
      uri += `&organizationId=${organizationId}`;
    }

    // Let's assign sorting
    if (sortBy != null && sortDirection != null) {
      uri += `&sortBy=${sortBy}&sortOrder=${sortDirection}`;
    }

    if (state != null) {
      uri += `&state=${state}`;
    }

    return this.laerdalApiInstance
      .get(uri, abortSignal ? { signal: abortSignal } : {})
      .then((response) => response?.data)
      .catch((error) => Promise.reject(error?.response?.status));
  };

  /**
   * Find and retrieves a list of organizations from the API.
   * @param query - Query based on which to filter the organization results.
   * @param start - From which row to retrieve data (offset).
   * @param count - How many rows to retrieve (limit).
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns A record wrapper containing found organization list.
   */
  FindOrganizations = async (
    query: string,
    start?: number,
    count?: number,
    sortBy?: number,
    sortDirection?: number,
    abortSignal?: AbortSignal,
  ): Promise<OrganizationRecordWrapper> => {
    let uri = `/api/organization/find?query=${query}`;

    if (start != null) {
      uri += `&start=${start}`;
    }
    if (count != null) {
      uri += `&count=${count}`;
    }

    // Let's assign sorting
    if (sortBy !== undefined && sortDirection !== undefined) {
      uri += `&sortBy=${sortBy}&sortOrder=${sortDirection}`;
    }

    return this.laerdalApiInstance
      .get(uri, { signal: abortSignal })
      .then((response) => response?.data)
      .catch((error) => Promise.reject(error?.response?.status));
  };

  /**
   * Retrieves organization with specific customer no.
   * @param customerNo - Customer No of the organization which needs to be found.
   * @returns Details of the organization with the specific customer no.
   */
  GetOrganizationByCustomerNo = async (customerNo: string): Promise<Organization> => {
    return this.laerdalApiInstance.get(`/api/organization/getByCustomerNo?customerNo=${customerNo}`).then((response) => Promise.resolve(response.data));
  };

  /**
   * Retrieves information about a specific organization from the API.
   * @param id - ID of the organization which needs to be retrieved.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns Information about a specific organization.
   */
  GetOrganization = async (id: string, abortSignal?: AbortSignal): Promise<Organization> => {
    return this.laerdalApiInstance.get(`/api/organization/get?id=${id}`, { signal: abortSignal }).then((response) => Promise.resolve(response.data));
  };

  /**
   * Get lists of default permissions for new members of organization
   * @param id - ID of the organization which needs to be retrieved.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns Information about a specific organization.
   */
  GetOrganizationMemberDefaultPermissions = async (id: string, abortSignal?: AbortSignal): Promise<string[]> => {
    return this.laerdalApiInstance.get(`/api/organization/getNewMemberDefaultPermissions?id=${id}`, { signal: abortSignal }).then((response) => Promise.resolve(response.data));
  };

  /**
   * Creates a new organization and retrieves it's data from the API.
   * @param organization - Organization object which needs to be created.
   * @returns Created organization data from the API.
   */
  CreateOrganization = async (organization: CreateOrganization): Promise<Organization> => {
    return this.laerdalApiInstance.post(`/api/organization/create`, organization).then((response) => Promise.resolve(response.data));
  };

  /**
   * Updates a specific organization in the API.
   * @param organization - Organization object that will be updated.
   * @returns - Promise without any body.
   */
  UpdateOrganization = async (organization: Organization): Promise<void> => {
    return this.laerdalApiInstance
      .post(`/api/organization/update`, organization, {
        headers: {
          EntityId: organization.id,
        },
      })
      .then(() => Promise.resolve());
  };

  OnboardOrganization = async (id: string, subject: string, message: string, adminRecipients: Recipient[], regularRecipients: Recipient[]): Promise<void> => {
    return this.laerdalApiInstance
      .post(
        `/api/organization/${id}/onboard`,
        { subject, message, adminRecipients, regularRecipients },
        {
          headers: {
            EntityId: id,
          },
        },
      )
      .then(() => Promise.resolve());
  };

  OnboardUser = async (orgId: string, email: string, adminRights: boolean): Promise<void> => {
    return this.laerdalApiInstance
      .post(`/api/organization/${orgId}/onboard/${email}?adminRights=${adminRights}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then(() => Promise.resolve());
  };

  /**
   * Retrieves a specific user information from the API.
   * @param id - ID of the user which needs to be retrieved.
   * @returns - A specific user information.
   */
  GetUser = async (id: string): Promise<User> => {
    return this.laerdalApiInstance.get(`/api/user/get?id=${encodeURIComponent(id)}`).then((response) => {
      if (response.status === 204) {
        return Promise.reject(response);
      }
      return response?.data;
    });
  };

  /**
   * Retrieves a specific user profile information from the API.
   * @param id - ID of the user for which the profile needs to be retrieved.
   * @returns - A specific user profle information.
   */
  GetUserProfile = async (id: string): Promise<UserProfile> => {
    return this.laerdalApiInstance.get(`/api/user/${encodeURIComponent(id)}/profile`).then((response) => {
      if (response.status === 204) {
        return Promise.reject(response);
      }
      return response?.data;
    });
  };

  /**
   * Retrieves a specific user profile information from the API.
   * @param id - ID of the user for which the profile needs to be retrieved.
   * @returns - A specific user profle information.
   */
  UpdateUserProfile = async (id: string, profile: UserProfile): Promise<void> => {
    return this.laerdalApiInstance.post(`/api/user/${encodeURIComponent(id)}/profile`, profile).then((response) => {
      if (response.status === 204) {
        return Promise.reject(response);
      }
      return response?.data;
    });
  };

  /**
   * Trigger reset password flow for user in SAP CDC
   * @param email - email of the user
   * @returns - boolean.
   */
  ResetPassword = async (email: string): Promise<void> => {
    return this.laerdalApiInstance
      .post(
        `/api/user/resetPassword?email=${email}`,
        {},
        {
          headers: {
            EntityId: email,
          },
        },
      )
      .then((response) => {
        if (response.status === 204) {
          return Promise.reject(response);
        }
        return response?.data;
      })
      .catch((error) => Promise.reject(error?.response?.status));
  };

  /**
   * Retrieves a list of countries from the API.
   * @returns A list of countries.
   */
  GetCountries = async (): Promise<CountryDto[]> => {
    return this.laerdalApiInstance.get(`/api/organization/countries`).then((response) => response?.data);
  };

  /**
   * Retrieves a list of countries from the API.
   * @returns A list of countries.
   */
  GetPermissions = async (): Promise<Permission[]> => {
    return this.laerdalApiInstance.get(`/api/organizationMember/allPermissions`).then((response) => response?.data);
  };

  /**
   * Retrieves a list of sales organizations from the API.
   * @returns A list of sales organizations.
   */
  GetSalesOrgs = async (): Promise<SalesOrgDto[]> => {
    return this.laerdalApiInstance.get(`/api/tos/salesOrganizations`).then((response) => response?.data);
  };

  /**
   * Retrieves a list of countries from the API.
   * @returns A list of countries.
   */
  GetToS = async (serviceId: string): Promise<TermsOfServiceDocument[]> => {
    return this.laerdalApiInstance.get(`/api/tos?serviceId=${serviceId}`).then((response) => response?.data);
  };

  /**
   * Deletes ToS including all versions
   * @param tosId id of ToS to delete
   * @returns Promise without return type
   */
  DeleteToS = async (tosId: string): Promise<void> => {
    return this.laerdalApiInstance.delete(`/api/tos/${tosId}`).then((response) => response?.data);
  };

  /**
   * Creates new ToS document
   * @param createToSDTO details of new ToS
   * @returns Promise without return type
   */
  CreateToS = async (createToSDTO: CreateTermsOfServiceDto): Promise<void> => {
    return this.laerdalApiInstance.post(`/api/tos`, createToSDTO).then((response) => response?.data);
  };

  /**
   * Create new ToS version
   * @param tosId Id of ToS for which we upload latest version
   * @param createToSVersionDTO details of new Version
   * @returns Promise without return type
   */
  CreateToSVersion = async (tosId: string, createToSVersionDTO: CreateTermsOfServiceDto): Promise<void> => {
    return this.laerdalApiInstance.post(`/api/tos/${tosId}/version`, createToSVersionDTO).then((response) => response?.data);
  };

  /**
   * Update existing ToS document
   * @param tosId Id of the ToS to be updated
   * @param tosDto DTO object containing name and salesOrgCode
   * @returns Promise without return type
   */
  UpdateToS = async (tosId: string, tosDto: UpdateToSDto): Promise<void> => {
    return this.laerdalApiInstance.post(`/api/tos/${tosId}`, tosDto).then((response) => response?.data);
  };

  /**
   * Uplods new files to ToS Container in Azure Blob Storage
   * @param uploadFiles files to upload
   * @returns list of blobnames for uploaded files
   */
  UploadFiles = async (uploadFiles: FormData): Promise<string[]> => {
    return this.laerdalApiInstance.post(`/api/tos/uploadFiles`, uploadFiles, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response) => response?.data);
  };

  /**
   * Clones files in Azure Blob Storage ToS Container
   * @param cloneFiles ids of files to Clone
   * @returns list of new blobnames
   */
  CloneFiles = async (cloneFiles: string[]): Promise<string[]> => {
    return this.laerdalApiInstance.post(`/api/tos/cloneFiles`, cloneFiles).then((response) => response?.data);
  };

  /**
   * Retrieves a list of languages from the API.
   * @returns A list of languages.
   */
  GetLanguages = async (): Promise<LanguageDto[]> => {
    return this.laerdalApiInstance.get(`/api/organization/languages`).then((response) => response?.data);
  };

  /**
   * Sets a specific organization as a current organization for a user.
   * @param userId - User ID for which to set a specific organization as a current organization.
   * @param organizationId - ID of the organization which needs to be set as a current organization.
   * @returns - Response from the API.
   */
  SetAsCurrentOrganization = async (userId: string, organizationId: string) => {
    return this.laerdalApiInstance
      .patch(`/api/user/${userId}/setCurrentOrganization/${organizationId}`, null, {
        headers: {
          EntityId: userId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Adds a specific user to organization
   * @param orgId - Organization ID to which user must be added.
   * @param usersToInvite - Organization Member Invitation object which needs to be added to the organization.
   * @param removeFromOtherOrgs - Existing users should be added to this organization and removed from other orgs
   * @returns - Added organization member(s) data.
   */
  InviteUsersToOrganization = async (
    orgId: string,
    usersToInvite: AddOrganizationMemberInvitation,
    removeFromOtherOrgs: boolean,
    addImmediately: boolean,
    createSFContacts: boolean,
  ): Promise<OrganizationInvitationResponse[]> => {
    return this.laerdalApiInstance
      .post(`/api/organization/${orgId}/member?removeFromOtherOrgs=${removeFromOtherOrgs}&addImmediately=${addImmediately}&createSFContacts=${createSFContacts}`, usersToInvite, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Updates a specific organization members permissions.
   * @param memberId - member ID for which to update the data.
   * @param newPermissions - New permissions which should be set.
   */
  UpdateOrganizationMemberPermissions = async (memberId: string, newPermissions: string[]): Promise<AxiosResponse<any, any>> => {
    return await this.laerdalApiInstance.post(`/api/organizationmember/${memberId}/permissions`, newPermissions);
  };

  /**
   * Updates a specific organization member data.
   * @param orgId - Organization ID for which to update the member data.
   * @param memberId - Member ID for which to update the data.
   * @param newRole - New role of the organization which should be set.
   * @returns Updated user data.
   */
  UpdateOrganizationMember = async (orgId: string, memberId: string, newRole: OrganizationRole): Promise<OrganizationMember> => {
    return this.laerdalApiInstance
      .patch(`/api/organization/${orgId}/member/${memberId}`, newRole, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Removes a specific member from an organization.
   * @param orgId - Organization ID from which user should be removed.
   * @param memberEmail - Member email which needs to be removed from the organization.
   * @returns Response from the API.
   */
  RemoveMemberFromOrganization = async (orgId: string, memberEmail: string): Promise<any> => {
    return this.laerdalApiInstance
      .delete(`/api/organization/${orgId}/member/${memberEmail}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Check organization with specific customer no was created in Salesforce.
   * @param customerNo - Customer No of the organization which needs to be found.
   * @returns A boolean indicating if the organization with the specific customer no was created in Salesfoce.
   */
  CheckOrganizationCreatedFromSalesforce = async (customerNo: string): Promise<boolean> => {
    return this.laerdalApiInstance.get(`/api/organization/checkOrganizationCreatedFromSalesforce?customerNo=${customerNo || ''}`).then((response) => {
      return response.status === 200;
    });
  };

  /**
   * Retrieves services tied to an organization from the API.
   * @param id - ID of the organization.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns A list of organization services.
   */
  GetOrganizationServices = async (id: string, abortSignal?: AbortSignal): Promise<OrganizationService[]> => {
    return this.laerdalApiInstance
      .get(`/api/organization/${id}/services`, {
        signal: abortSignal,
        headers: {
          EntityId: id,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Adds a service to an organization.
   * @param orgId - Organization ID for which to add the service.
   * @param serviceId - Service ID which needs to be added.
   * @returns A newly added service for the organization.
   */
  CreateOrganizationService = async (orgId: string, serviceId: string): Promise<OrganizationService> => {
    return this.laerdalApiInstance
      .post(
        `/api/organizationservice/${orgId}`,
        { serviceId },
        {
          headers: {
            EntityId: orgId,
          },
        },
      )
      .then((response) => response?.data);
  };

  /**
   * Retrieves a specific organization service data from the API.
   * @param orgId - Organization ID for which to retrieve the service data.
   * @param id - ID of the service which needs to be retrieved.
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns A specific organization service data.
   */
  GetOrganizationService = async (orgId: string, id: string, abortSignal?: AbortSignal): Promise<OrganizationService> => {
    return this.laerdalApiInstance
      .get(`/api/organizationservice/${id}`, {
        signal: abortSignal,
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Updates a specific organization service member data.
   * @param orgId
   * @param orgServiceId - Organization Service ID for which to update the member data.
   * @param memberId - Member ID for which to update the data.
   * @param newRole - New role of the organization which should be set.
   * @returns Updated user data.
   */
  UpdateOrganizationServiceMember = async (orgId: string, orgServiceId: string, memberId: string, newRole: ServiceRole): Promise<OrganizationServiceMember> => {
    return this.laerdalApiInstance
      .patch(`/api/organizationservice/${orgServiceId}/member/${memberId}`, newRole, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Removes a specific member from organization service.
   * @param orgId
   * @param orgServiceId - Organization service ID from which to remove the member.
   * @param memberId - Member ID which needs to be removed from the organization service.
   */
  RemoveMemberFromOrganizationService = async (orgId: string, orgServiceId: string, memberId: string): Promise<void> => {
    return this.laerdalApiInstance
      .delete(`/api/organizationservice/${orgServiceId}/member/${memberId}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then(() => {
        return;
      });
  };

  /**
   * Adds a specific member to the organization service.
   * @param orgId
   * @param orgServiceId - Organization service ID to which member needs to be added.
   * @param userId - User ID which needs to be added to the organization service.
   * @returns Status of the API call.
   */
  AddMemberToOrganizationService = async (orgId: string, orgServiceId: string, userId: string): Promise<number> => {
    return this.laerdalApiInstance
      .post(`/api/organizationservice/${orgServiceId}/member/${userId}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response: AxiosResponse) => response.status);
  };

  /**
   * Invites a specific member to the organization service.
   * @param orgId
   * @param orgServiceId - Organization service ID to which member needs to be invited.
   * @param inviteObj - User Invite object with details of users to which the organization service invitation needs to
   *   be sent to.
   * @returns Status of the API call.
   */
  InviteMemberToOrganizationService = async (orgId: string, orgServiceId: string, inviteObj: AddOrganizationServiceMemberInvitation | undefined): Promise<number> => {
    return this.laerdalApiInstance
      .post(`/api/organizationservice/${orgServiceId}/member/invite`, inviteObj, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response: AxiosResponse) => response.status);
  };

  /**
   * Deletes a organization service member invitation.
   * @param orgId
   * @param orgServiceId - Organization service ID to which member needs to be invited.
   * @param inviteCode - The invitation code
   * @returns Status of the API call.
   */
  DeleteOrganizationServiceInvitation = async (orgId: string, orgServiceId: string, inviteCode: string): Promise<number> => {
    return this.laerdalApiInstance
      .delete(`/api/organizationservice/${orgServiceId}/member/invite/${inviteCode}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response: AxiosResponse) => response.status);
  };

  UpdateUserDetails = async (user: UpdateUser): Promise<number> => {
    return axios
      .patch(`/api/user`, user, { headers: { Authorization: `Bearer ${this.getToken()}`, EntityId: user.uid } })
      .then((response: AxiosResponse) => {
        return response.status;
      })
      .catch((error: AxiosError) => {
        console.log(error);
        return Promise.reject(error.response?.status);
      });
  };

  /**
   * Updates a specific organization invitation (revoke invitation or resend invitation e-mail).
   * @param orgId - Organization ID for which to update the member data.
   * @param invitationCode - Invitation code for the organization invitation.
   * @param resendInvitationEmail - Flag for resending invitation e-mail.
   * @param revokeInvitation - Flag for revoking invitation (default: false).
   */
  UpdateOrganizationInvitation = async (
    orgId: string,
    invitationCode: string,
    resendInvitationEmail: boolean,
    revokeInvitation: boolean = false,
    updatePermissions: boolean = false,
    permissions: string[] = [],
  ): Promise<void> => {
    return this.laerdalApiInstance
      .post(
        `/api/organization/${orgId}/invite/${invitationCode}?resendInvitationEmail=${resendInvitationEmail}&revokeInvitation=${revokeInvitation}&updatePermissions=${updatePermissions}`,
        permissions,
        {
          headers: {
            EntityId: orgId,
          },
        },
      )
      .then(() => {
        return;
      });
  };

  /**
   * Updates a specific organization invitation role.
   * @param orgId - Organization ID for which to update the member data.
   * @param invitationCode - Invitation code for the organization invitation.
   * @param newRole - New role of the invited user upon accepting invitation.
   * @returns Updated user data.
   */
  UpdateOrganizationInvitationRole = async (orgId: string, invitationCode: string, newRole: OrganizationRole): Promise<void> => {
    return this.laerdalApiInstance
      .patch(`/api/organization/${orgId}/invite/${invitationCode}`, newRole, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Gets user localization information from SAP CDC.
   * @param uid - uid of the user.
   * @returns User localization information.
   */
  GetUserLocalization = async (uid: string): Promise<UpdateUserLocalizationDTO> => {
    return this.laerdalApiInstance
      .get(`/api/user/localization?uid=` + uid, {
        headers: {
          EntityId: uid,
        },
      })
      .then((response) => {
        return response?.data;
      });
  };

  /**
   * Gets audit logs for user.
   * @param uid - uid of the user.
   * @returns User history events.
   */
  GetUserAuditLogs = async (
    uid: string,
    page: number,
    pageSize: number,
    eventTypes?: AuditLogEventType[],
    from?: Date,
    to?: Date,
    entityEventType?: string,
  ): Promise<AuditLogEventsRecordsWrapper> => {
    return this.laerdalApiInstance
      .get(
        `/api/user/auditlogs?uid=${uid}&${entityEventType ? `&eventType=${entityEventType}` : ''}&page=${page}&pageSize=${pageSize}&events=${eventTypes ?? '[]'}${
          from ? `&from=${from.toDateString()}` : ''
        }${to ? `&to=${to.toDateString()}` : ''}`,
        {
          headers: {
            EntityId: uid,
          },
        },
      )
      .then((response) => {
        return response?.data;
      });
  };

  /**
   * Gets audit logs for organization.
   * @param uid - uid of the organization.
   * @returns User history events.
   */
  GetOrganizationAuditLogs = async (
    uid: string,
    page: number,
    pageSize: number,
    eventTypes?: AuditLogEventType[],
    from?: Date,
    to?: Date,
    entityEventType?: string,
  ): Promise<AuditLogEventsRecordsWrapper> => {
    return this.laerdalApiInstance
      .get(
        `/api/organization/auditlogs?uid=${uid}&${entityEventType ? `&eventType=${entityEventType}` : ''}&page=${page}&pageSize=${pageSize}&events=${eventTypes ?? '[]'}${
          from ? `&from=${from.toDateString()}` : ''
        }${to ? `&to=${to.toDateString()}` : ''}`,
        {
          headers: {
            EntityId: uid,
          },
        },
      )
      .then((response) => {
        return response?.data;
      });
  };

  /**
   * Gets user localization information from SAP CDC.
   * @param request - UpdateUserLocalizationDTO.
   * @returns Status of the API call.
   */
  UpdateUserLocalization = async (request: UpdateUserLocalizationDTO): Promise<void> => {
    return this.laerdalApiInstance
      .patch(`/api/user/localization`, request, {
        headers: {
          EntityId: request.uid,
        },
      })
      .then((response) => {
        return response?.data;
      });
  };

  /**
   * Creates a signin on behalf request
   * @returns Updated user data.
   * @param request
   */
  SigninOnBehalf = async (request: ImpersonateUserRequestDTO): Promise<ImpersonateUserRequestDTO> => {
    return this.laerdalApiInstance
      .post(`/api/user/signin-on-behalf`, request, {
        headers: {
          EntityId: request.impersonatedUserId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Validate domain for TRP Service
   * @param domain - domain to be validated
   * @returns Validation object
   */
  ValidateDomain = async (domain: string): Promise<DomainValidationDTO> => {
    return this.laerdalApiInstance.get(`/api/organizationservice/domain/${domain}`).then((response) => response?.data);
  };

  /**
   * Search scenarios by title or sms number in Scenario Cloud API
   * @param query - search query
   * @returns all scenarios containing search query in name or SMS number
   */
  SearchScenarios = async (query: string): Promise<ScenarioDTO[]> => {
    return this.laerdalApiInstance.get(`/api/organizationscenario/search?query=${query}`).then((response) => Promise.resolve(response.data));
  };

  /**
   * Get whitelisted scenarios for organization in Scenario Cloud API
   * @param organizationId - organization id
   * @returns all whitelisted scenarios already added for organization
   */
  GetOrganizationScenarios = async (organizationId: string): Promise<ScenarioWhitelistItemDTO[]> => {
    return this.laerdalApiInstance
      .get(`/api/organizationscenario/${organizationId}`, {
        headers: {
          EntityId: organizationId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Get whitelisted scenarios for organization in Scenario Cloud API
   * @param organizationId - organization id
   * @param itemId
   * @returns all whitelisted scenarios already added for organization
   */
  DeleteScenarioItem = async (organizationId: string, itemId: string): Promise<void> => {
    return this.laerdalApiInstance.delete(`/api/organizationscenario/${organizationId}/${itemId}`, {
      headers: {
        EntityId: organizationId,
      },
    });
  };

  /**
   * Edit exiting item in organization scenarios whitelist in Scenario Cloud API
   * @param organizationId - organization id
   * @param item
   */
  EditScenarioItem = async (organizationId: string, item: ScenarioItemPatchDTO): Promise<void> => {
    return this.laerdalApiInstance.post(`/api/organizationscenario/${organizationId}/${item.id}`, item, {
      headers: {
        EntityId: organizationId,
      },
    });
  };

  /**
   * Add new items in organization scenarios whitelist in Scenario Cloud API
   * @param organizationId - organization id
   * @param items - array of new items to add
   */
  AddScenarioItems = async (organizationId: string, items: ScenarioItemDTO[]): Promise<void> => {
    return this.laerdalApiInstance.patch(`/api/organizationscenario/${organizationId}`, items, {
      headers: {
        EntityId: organizationId,
      },
    });
  };

  /**
   * Add new items in organization scenarios whitelist in Scenario Cloud API
   */
  UpdateServiceSpecificSettings = async (orgId: string, organizationServiceId: string, settings: UpdateOrganizationServiceDTO, abortSignal?: AbortSignal): Promise<void> => {
    return this.laerdalApiInstance.put(`/api/organizationservice/${organizationServiceId}`, settings, { signal: abortSignal, headers: { EntityId: orgId } });
  };

  /**
   * Removes an organization service from an organization.
   * @param orgId
   * @param orgServiceId - Organization Service ID to delete.
   * @returns Response from the API.
   */
  DeleteOrganizationLicense = async (orgId: string, orgServiceId: string): Promise<any> => {
    return this.laerdalApiInstance
      .delete(`/api/organizationservice/${orgServiceId}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Retrieve available markets
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns Response from the API.
   */
  GetMarkets = async (abortSignal?: AbortSignal): Promise<MarketDTO[]> => {
    return this.laerdalApiInstance.get('api/market', { signal: abortSignal }).then((a) => a.data);
  };

  /**
   * Retrieve all sessions for the subscription
   * @param orgId
   * @param subscriptionId - subscription id to retrieve sessions for
   * @param abortSignal - AbortSignal to cancel the request.
   * @returns Response from the API.
   */
  GetSessions = async (orgId: string, subscriptionId: string, abortSignal?: AbortSignal): Promise<SessionDTO[]> => {
    return this.laerdalApiInstance
      .get(`api/session/${subscriptionId}`, {
        signal: abortSignal,
        headers: {
          EntityId: orgId,
        },
      })
      .then((a) => a.data);
  };

  /**
   * Validates merge request
   * */
  ValidateOrganizationMerge = async (request: OrganizationMergeRequest, abortSignal?: AbortSignal): Promise<OrganizationMergeValidationResponse> => {
    return this.laerdalApiInstance
      .post(`api/organization/merge/validate`, request, {
        signal: abortSignal,
      })
      .then((a) => a.data);
  };

  /**
   * organization merge request
   * */
  MergeOrganizations = async (request: OrganizationMergeRequest, abortSignal?: AbortSignal): Promise<OrganizationMergeValidationResponse> => {
    return this.laerdalApiInstance
      .post(`api/organization/merge`, request, {
        signal: abortSignal,
      })
      .then((a) => a.data);
  };

  /**
   * organization merge request
   * */
  DeleteOrganization = async (orgId: string): Promise<any> => {
    return this.laerdalApiInstance
      .delete(`api/organization/${orgId}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((a) => a.data);
  };

  /**
   * Retrieves information if the user has verfied its account.
   * @param email - User email in gigya
   * @returns Boolean value indicating if the user has Gigya account.
   */
  IsVerified = async (email: string | null): Promise<{ isVerified: boolean; uid: string }> => {
    return this.laerdalApiInstance
      .get(`/api/user/is-verified?email=${email ? encodeURIComponent(email) : ''}`, {
        headers: {
          EntityId: email ?? '',
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Verifies user's account in  gigya.
   * @param uid - User id in gigya
   * @returns - boolean.
   */
  VerifyAccount = async (uid: string): Promise<void> => {
    return this.laerdalApiInstance
      .post(
        `/api/user/verify-account?uid=${uid}`,
        {},
        {
          headers: {
            EntityId: uid,
          },
        },
      )
      .then((response) => {
        if (response.status === 204) {
          return Promise.reject(response);
        }
        return response?.data;
      })
      .catch((error) => Promise.reject(error?.response?.status));
  };

  /**
   * Retrieve features
   **/
  GetFeatures = async (abortSignal?: AbortSignal): Promise<Feature[]> => {
    return this.laerdalApiInstance
      .get(`/api/feature`)
      .then((response) => {
        return response?.data;
      })
      .catch(() => []);
  };

  /**
   * Sync organization data to sales force
   * */
  SyncOrganizationToSalesForce = async (orgId: string): Promise<void> => {
    return this.laerdalApiInstance.post(`api/organization/${orgId}/sync`, {
      headers: {
        EntityId: orgId,
      },
    });
  };

  AddUserToOrganization = async (
    organizationId: string,
    userId: string,
    email: string,
    sendEmail: boolean,
    addImmediately: boolean,
    removeFromOtherOrganizations: boolean,
  ): Promise<InvitationResponse | AddOrgMemberResponse | undefined> => {
    return this.laerdalApiInstance
      .post(
        `api/user/add-to-organization`,
        {
          organizationId,
          userId,
          email,
          sendEmail,
          addImmediately,
          removeFromOtherOrganizations,
        },
        {
          headers: {
            EntityId: userId,
          },
        },
      )
      .then((a) => {
        return a.data;
      });
  };

  /**
   * Notifies SimCapture about new subscription created in CC
   */
  NotifySimCapture = async (
    salesforceId: string,
    clientName: string,
    requestorEmail: string,
    subdomain: string,
    countryCode: string,
    tier: string,
    comments: string,
    organizationId: string,
  ): Promise<User> => {
    return this.laerdalApiInstance
      .post(`api/organizationService/notifySimCapture`, {
        salesforceId: salesforceId,
        clientName: clientName,
        requestorEmail: requestorEmail,
        subdomain: subdomain,
        countryCode: countryCode,
        tier: tier,
        comments: comments,
        laerdalOrganizationId: organizationId,
      })
      .then((response) => response?.data);
  };


  /**
   * Get country configurations
   **/
  GetCountryConfigurations = async (abortSignal?: AbortSignal): Promise<{ [key: string]: CountryConfiguration }> => {
    const request = () =>
      this.laerdalApiInstance
        .get(`/api/subscription/settings/countries`, {
          signal: abortSignal,
        })
        .then((response: AxiosResponse<CountryConfiguration[]>) => {
          return response?.data.reduce((acc, curr) => ({ ...acc, [curr.countryCode]: curr }), {});
        });

    return this.GetCached('GetCountryConfigurations', '', request);
  };

  /**
   *  Get Salesforce managed services
   **/
  GetSelfServiceServices = async (abortSignal?: AbortSignal): Promise<string[]> => {
    const request = () =>
      this.laerdalApiInstance
        .get(`/api/subscription/settings/self-served-services`, {
          signal: abortSignal,
        })
        .then((response) => {
          return response?.data;
        });

    return this.GetCached('GetSelfServiceServices', '', request);
  };

  /**
   * Creates an organization service subscription.
   * @param orgId
   * @param subscription - Subscription object which will be created.
   * @returns Response from the API.
   */
  CreateSubscription = async (orgId: string, subscription: SubscriptionCreation): Promise<Subscription> => {
    return this.laerdalApiInstance
      .post(`/api/subscription`, subscription, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   *  Get subscription details from checkout
   **/
  GetSubscription = async (orgId: string, id: string, abortSignal?: AbortSignal): Promise<Subscription> => {
    return this.laerdalApiInstance
      .get(`/api/subscription/${id}`, {
        signal: abortSignal,
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => {
        return response?.data;
      });
  };

  /**
   * Updates an organization service subscription.
   * @param orgId
   * @param id - ID of the organization service for which to update the subscription.
   * @param subscription - Subscription object which will be updated.
   * @returns Response from the API.
   */
  UpdateSubscription = async (orgId: string, id: string, subscription: SubscriptionUpdate): Promise<Subscription> => {
    return this.laerdalApiInstance
      .put(`/api/subscription/${id}`, subscription, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Create scheduled subscription amendment.
   * @param orgId
   * @param id - ID of the organization service for which to update the subscription.
   * @param amendment - Subscription object which will be updated.
   * @returns Response from the API.
   */
  CreateSubscriptionScheduledAmendment = async (orgId: string, id: string, amendment: SubscriptionCreateScheduledAmendmentDto): Promise<SubscriptionAmendment> => {
    return this.laerdalApiInstance
      .post(`/api/subscription/${id}/amend`, amendment, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   * Deletes a subscription with provided id.
   * @param id - ID of the subscription that we want to delete.
   * @param orgId
   * @returns Response from the API.
   */
  DeleteSubscription = async (orgId: string, id: string): Promise<void> => {
    return this.laerdalApiInstance
      .delete(`/api/subscription/${id}`, {
        headers: {
          EntityId: orgId,
        },
      })
      .then((response) => response?.data);
  };

  /**
   *  Cancel subscription
   **/
  CancelSubscription = async (orgId: string, id: string, abortSignal?: AbortSignal): Promise<void> => {
    return this.laerdalApiInstance.post(
      `/api/subscription/${id}/cancel`,
      {},
      {
        signal: abortSignal,
        headers: {
          EntityId: orgId,
        },
      },
    );
  };

  /**
   *  Deactivate subscription
   **/
  DeactivateSubscription = async (orgId: string, id: string, abortSignal?: AbortSignal): Promise<void> => {
    return this.laerdalApiInstance.post(
      `/api/subscription/${id}/deactivate`,
      {},
      {
        signal: abortSignal,
        headers: {
          EntityId: orgId,
        },
      },
    );
  };

  /**
   * Retrieves a list of local segments from the API.
   * @returns A list of segments.
   */
  GetLocalSegments = async (): Promise<LocalSegment[]> => {
    return this.laerdalApiInstance.get(`/api/organization/localSegments`).then((response) => response?.data);
  };

}

export default Api.instance;
