import {
  BillingInterval,
  FeatureNames,
  InstanceLimitType,
  OrganizationMember,
  OrganizationServiceMember,
  Service,
  SessionDTO,
  Subscription,
  SubscriptionAmendment,
  SubscriptionAmendmentType,
  SubscriptionBillingDetails,
  SubscriptionStatus,
  VALID_SUBSCRIPTION_STATUSES
} from "../../../../types";
import moment from "moment";
import {TFunction} from "i18next";

export class LicenseInformationHelper {
  static IsActive(subscription?: Subscription) {
    return !!subscription && VALID_SUBSCRIPTION_STATUSES.includes(subscription!.status!);
  }

  static CanAddUsers(service?: Service, subscription?: Subscription, members?: OrganizationServiceMember[]) {
    if (!members || !subscription) return false;

    const isKnowledgeHub = service?.id?.toLocaleLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase();

    return !isKnowledgeHub && (
      service?.instanceLimitType == InstanceLimitType.Session ||
      service?.instanceLimitType == InstanceLimitType.Role ||
      !subscription?.maxSubscriptionInstances ||
      members?.length < subscription?.maxSubscriptionInstances);
  }

  static CanCancel(subscription?: Subscription) {
    if (!subscription) return false;

    const isLastDay =
      moment(subscription?.expirationDate ?? subscription?.nextBillingDate)
        .startOf('day')
        .add(-1, "day")
        .isBefore(moment());


    if (subscription?.status == SubscriptionStatus.Trial) return false;

    return !!subscription &&
      subscription.autoRenew &&
      subscription.status == SubscriptionStatus.Active &&
      !isLastDay &&
      (!!subscription.paddle || !!subscription.salesforce);

  }

  static CanRenew(subscription?: Subscription) {
    const isLastDay =
      moment(subscription?.expirationDate ?? subscription?.nextBillingDate)
        .startOf('day')
        .add(-1, "day")
        .isBefore(moment());

    if (!subscription) return false;

    if (!VALID_SUBSCRIPTION_STATUSES.includes(subscription.status)) return false;

    if (subscription?.status == SubscriptionStatus.Trial) return false;

    if (subscription?.autoRenew) return false;

    if (subscription?.billingInterval == BillingInterval.Monthly) return false;

    if (isLastDay) return false;

    return !!subscription.paddle || !!subscription.salesforce;
  }

  static CanChange(subscription?: Subscription) {
    if (!subscription) return false;

    const isLastDay =
      moment(subscription?.expirationDate ?? subscription?.nextBillingDate)
        .startOf('day')
        .add(-1, "day")
        .isBefore(moment());

    return !!subscription &&
      subscription.status == SubscriptionStatus.Active &&
      !isLastDay &&
      (!!subscription.paddle || !!subscription.salesforce);
  }

  static Duration(subscription?: Subscription) {
    return subscription?.billingInterval === BillingInterval.FiveYear
      ? 60
      : subscription?.billingInterval === BillingInterval.ThreeYear
        ? 36
        : subscription?.billingInterval === BillingInterval.Monthly
          ? 1
          : subscription?.renewalPeriodInMonths;
  }

  static UserCount(service?: Service, serviceMembers?: OrganizationServiceMember[], sessions?: SessionDTO[], organizationMembers?: OrganizationMember[]) {
    const isKnowledgeHub = service?.id?.toLocaleLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase();
    return isKnowledgeHub
      ? organizationMembers?.length || 0
      : service?.instanceLimitType == InstanceLimitType.Session
        ? sessions?.length || 0
        : serviceMembers?.length || 0;
  }

  static MaxUserCount(subscription?: Subscription) {
    return subscription?.maxSubscriptionInstances
  }

  static IsUnlimited(subscription?: Subscription) {
    return !subscription?.maxSubscriptionInstances;
  }

  static WillRenew(subscription?: Subscription) {
    if (!subscription) return false;

    if (!VALID_SUBSCRIPTION_STATUSES.includes(subscription.status)) return false;

    if (subscription?.status == SubscriptionStatus.Trial && subscription?.autoUpgradeTrial) return true;

    if (subscription.status == SubscriptionStatus.Canceled) return false;

    return subscription.autoRenew;
  }

  static IsExpired(subscription?: Subscription) {
    return subscription?.status === SubscriptionStatus.Expired ||
      subscription?.status === SubscriptionStatus.Deactivated;
  }

  static WillExpire(subscription?: Subscription) {
    return !this.WillRenew(subscription) && this.IsActive(subscription) && !!subscription?.expirationDate;
  }

  static RenewalDate(subscription?: Subscription) {
    return this.WillRenew(subscription)
      ? subscription?.nextBillingDate ?? subscription?.expirationDate
      : undefined;
  }

  static ExpiredOn(subscription?: Subscription) {
    return subscription?.status == SubscriptionStatus.Deactivated
      ? subscription?.deactivationDate
      : subscription?.actualExpiredDate ?? subscription?.expirationDate;
  }

  static DaysLeft(subscription?: Subscription) {
    return moment(subscription?.expirationDate).startOf('day').diff(moment().startOf('day'), 'days');
  }

  static WillUpgrade(amendment: SubscriptionAmendment | undefined) {
    return amendment &&
      !!(
        amendment.amendments & SubscriptionAmendmentType.Upgrade ||
        amendment.amendments & SubscriptionAmendmentType.QuantityIncrease
      ) &&
      !this.WillTerminate(amendment);
  }

  static WillDowngrade(amendment: SubscriptionAmendment | undefined) {
    return amendment &&
      !!(
        amendment.amendments & SubscriptionAmendmentType.Downgrade ||
        amendment.amendments & SubscriptionAmendmentType.QuantityDecrease
      ) &&
      !this.WillTerminate(amendment);
  }

  static WillTerminate(amendment: SubscriptionAmendment | undefined) {
    return amendment && !!(amendment.amendments & SubscriptionAmendmentType.Terminate)
  }

  static WillChangeTerm(amendment: SubscriptionAmendment | undefined) {
    return amendment && !!(amendment.amendments & SubscriptionAmendmentType.TermChange) && !this.WillTerminate(amendment);
  }

  static FormatUpgradeDowngradeText(t: TFunction, service: Service | undefined, amendment: SubscriptionAmendment | undefined) {
    if (!service || !amendment) return '';

    const plan = service.availablePlans?.find(p => p.id === amendment.planId);
    const tier = plan?.tiers?.find(t => t.id === amendment.planTierId);

    if (!tier) return '';

    if (tier.individualLicensing) {
      if (service.instanceLimitType == InstanceLimitType.Session) {

        return amendment.maxSubscriptionInstances == 1
          ? t('1 seat', {amount: amendment.maxSubscriptionInstances})
          : t('{{amount}} seats', {amount: amendment.maxSubscriptionInstances});
      } else {
        return amendment.maxSubscriptionInstances == 1
          ? t('1 user', {amount: amendment.maxSubscriptionInstances})
          : t('{{amount}} users', {amount: amendment.maxSubscriptionInstances});
      }
    } else {
      return `${plan!.name} ${tier.name}`
    }
  }

  static FormatTermChangeText(t: TFunction, service: Service | undefined, amendment: SubscriptionAmendment | undefined) {
    if (!service || !amendment) return '';

    switch (amendment.billingInterval) {
      case BillingInterval.Monthly:
        return t('Monthly');
      case BillingInterval.Yearly:
        if (amendment.duration > 12) {
          return t('{{x}} Years, paid annually', {x: amendment.duration / 12});
        } else {
          return t('1 Year');
        }
      case BillingInterval.ThreeYear:
        return t('3 Years, prepaid');
      case BillingInterval.FiveYear:
        return t('5 Years, prepaid');
    }
  }

  static CanAmend(subscription: Subscription | undefined, checkFeature?: (feature: FeatureNames) => boolean) {
    if (!subscription) return false;

    if (checkFeature && (subscription?.paddle || subscription?.salesforce) && !checkFeature(FeatureNames.SubscriptionAmendments)) return false;

    if (subscription.billingInterval == BillingInterval.Monthly && moment(subscription.nextBillingDate).isBefore(moment().add(1, 'day'))) return false;

    if (subscription.billingInterval != BillingInterval.Monthly && moment(subscription.expirationDate).isBefore(moment().add(1, 'day'))) return false;

    if (subscription.billingInterval == BillingInterval.Monthly && subscription.status == SubscriptionStatus.Canceled) return false;

    if (!subscription.amendment) return true;

    if (moment(subscription.amendment.amendmentDate).isBefore(moment().add(1, 'day'))) return false;

    return !(subscription.amendment.amendments & SubscriptionAmendmentType.Terminate);
  }

  static IsCardExpired(billingDetails: SubscriptionBillingDetails | undefined) {
    return this.CardExpiryDate(billingDetails)! < new Date();
  }

  static CardExpiryDate(billingDetails: SubscriptionBillingDetails | undefined) {
    let expiryYear = +(billingDetails?.cardExpiryYear ?? 0);
    if (expiryYear < 100) {
      expiryYear += new Date().getFullYear() - new Date().getFullYear() % 100;
    }
    const cardExpiryDate = !!billingDetails?.cardExpiryMonth ? new Date(expiryYear, +(billingDetails?.cardExpiryMonth ?? 0) - 1) : undefined;

    return cardExpiryDate;
  }

  static CanExpire(subscription: Subscription | undefined) {
    return subscription?.expirationDate || subscription?.nextBillingDate;
  }

}
